import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Email from "../../assests/images/email-icon.svg";
// import Instagram from "../../assests/images/instagram-icon.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <footer>
          <Container>
            <Row>
              <Col>
                <a href="/">
                  <div className="footer-title">Build Your Faith App</div>
                </a>
              </Col>
            </Row>
            <div className="pt-5">
              <Row>
                <Col>
                  <a href="/">
                    <div className="footer-element"> HOME </div>
                  </a>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <a href="#">
                    <div className="footer-element">TERMS AND CONDITIONS</div>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href="#">
                    <div className="footer-element">PRIVACY POLICY</div>
                  </a>
                </Col>
              </Row> */}
            </div>
            <div className="pt-5">
              <Row>
                <Col>
                  <div className="d-flex">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKWxwtyzGdSJyrRFY_NoHPMWF983bsQwrrvx2MzFIZCcU_Ug/viewform?pli=1" target="_blank" rel="noreferrer noopener">
                      <img
                        src={Email}
                        alt="email"
                        className="email-icon"
                      />
                    </a>
                    {/* <a href="#">
                      <img
                        src={Instagram}
                        alt="instagram"
                        className="footer-icon instagram-icon"
                      />
                    </a> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
