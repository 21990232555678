import React from "react";
import BuildYourFaithLogo from "../../assests/images/build-your-faith-logo.svg";

const Header = () => {
  return (
    <div className="header">
      <div className="header-box fixed-top">
        <div className="d-flex align-items-center justify-content-center header-inner-box">
          <a href="/"><img src={BuildYourFaithLogo} alt="build-your-faith-logo" /></a>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="pt-sm-4 pt-2">
          <a href="/"><h1>BUILD YOUR FAITH APP</h1></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
