import React from "react";
import Header from "../../header/Header";
import HomepageVector from "../../../assests/images/homepage-vector.png";
import HomepageVectorMobile from "../../../assests/images/homepage-vector-sm.png";
import HomepageMockupTop from "../../../assests/images/homepage-mockup-top.png";
import ZoesWellnessworld from "../../../assests/images/build-your-faith-logo.svg";
import GratitudesGame from "../../../assests/images/1000-gratitudes-game-button.png";
import HomepageVectorBottom from "../../../assests/images/homepage-vector-bottom.svg";
import HomepageBottomMockup1 from "../../../assests/images/homepage-bottom-mockup-1.png";
import HomepageBottomMockup2 from "../../../assests/images/homepage-bottom-mockup-2.png";
import HomepageBottomMockup3 from "../../../assests/images/homepage-bottom-mockup-3.png";
import HomepageVectorBottomMobile from "../../../assests/images/homepage-bottom-vector-sm.png";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../footer/Footer";

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />

      {/*------------------------------------------- Hero Section web-------------------------------------------------------------------*/}

      <div className="d-sm-block d-none">
        <div className="hero-banner">
          <img
            src={HomepageVector}
            alt="homepage-banner"
            className="img-fluid w-100 homepage-vector"
          />
          <div className="homepage-overlay-top">
            <Container className="homepage-overlay-top-inner">
              <Row>
                <Col className="text-end mockup-top">
                  <img
                    src={HomepageMockupTop}
                    alt="build-your-faith-mobile-app"
                    className="img-fluid homepage-mockup-top"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  className="d-flex align-items-end justify-content-end"
                  style={{ paddingRight: "32px" }}
                >
                  <div
                    style={{ width: "556px", paddingTop: "54px" }}
                    className="text-end"
                  >
                    <h1>
                      This beautiful app encourages and empowers you during
                      difficult times
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  className="d-flex align-items-end justify-content-end"
                  style={{ paddingRight: "32px", paddingTop: "41px" }}
                >
                  <a
                    href="https://app.buildyourfaithapp.com"
                    className="btn btn-primary"
                    style={{ width: "268px" }}
                  >
                    Try for Free
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      {/*--------------------------------------------------- Hero Section mobile-----------------------------------------------------------*/}

      <div className="d-sm-none d-block">
        <div className="hero-banner-sm">
          <img
            src={HomepageVectorMobile}
            alt="homepage-banner-mobile"
            className="img-fluid w-100 homepage-vector-sm"
          />
          <div className="homepage-overlay-top-sm">
            <Container className="homepage-overlay-top-inner-sm">
              <Row>
                <Col className="text-end">
                  <img
                    src={HomepageMockupTop}
                    alt="build-your-faith-mobile-app"
                    className="img-fluid homepage-mockup-top-sm"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-end justify-content-end">
                  <div
                    style={{
                      width: "250px",
                      paddingTop: "175px",
                      paddingRight: "12px",
                    }}
                    className="text-end"
                  >
                    <h1>
                      This beautiful app encourages and empowers you during
                      difficult times
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "15px" }}>
                <Col>
                  <a
                    href="https://app.buildyourfaithapp.com"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                  >
                    Try for Free
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------Hero section end---------------------------------------------------- */}

      <Container className="container-after-overlay">
        <Row>
          <Col>
            <h2>
              Be lifted up and <br className="d-sm-none d-block" /> strengthened
              in your faith
            </h2>
            <div className="pt-4 pb-sm-4 pb-3">
              <p>
                The Build Your Faith app is designed to uniquely support{" "}
                <br className="d-sm-block d-none" />
                individuals and families facing health challenges by offering:
              </p>
            </div>
            <ul>
              <li>Life-giving affirmations</li>
              <li>Healing prayers</li>
              <li>Encouraging interactive activities</li>
            </ul>
            <div className="middle-button">
              <a href="https://app.buildyourfaithapp.com" className="btn btn-primary btn-middle">
                Try for Free
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="middle-button-section">
        <Container>
          <Row>
            <Col sm={6}>
              <Row>
                <div className="d-flex align-items-center justify-content-center">
                  <a href="https://app.buildyourfaithapp.com">
                    <div className="d-flex align-items-center justify-content-center rounded-circle border border-white">
                      <img
                        src={ZoesWellnessworld}
                        alt="zoes-wellness-world"
                        width={"56.5px"}
                        height={"87.1px"}
                      />
                    </div>
                  </a>
                </div>
              </Row>
              <Row className="pt-sm-0 pt-5">
                <Col>
                  <div className="d-flex align-items-center justify-content-center">
                    <a href="https://app.buildyourfaithapp.com">
                      <h3>ZOE’S WELLNESS WORDS</h3>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={6} className="button-section-mobile-seperator">
              <Row>
                <div className="d-flex align-items-center justify-content-center">
                  <a href="https://app.buildyourfaithapp.com">
                    <div className="d-flex align-items-center justify-content-center rounded-circle border border-white">
                      <img
                        src={GratitudesGame}
                        alt="zoes-wellness-world"
                        width={"110px"}
                      />
                    </div>
                  </a>
                </div>
              </Row>
              <Row className="pt-sm-0 pt-5">
                <Col>
                  <div className="d-flex align-items-center justify-content-center">
                    <a href="https://app.buildyourfaithapp.com">
                      <h3>1000 GRATITUDES GAME</h3>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="middle-text-section">
        <Container>
          <div className="d-flex align-items-center justify-content-center">
            <div style={{ width: "1014px" }}>
              <h2 className="text-center">
                Daily encouragement to help you through the difficult days
              </h2>
            </div>
          </div>
        </Container>
      </div>

      {/* ---------------------------------------------------Bottom mockups section----------------------------------------------------------*/}

      <div className="bottom-mobile-mockup-section d-sm-block d-none">
        <img
          src={HomepageVectorBottom}
          alt="homepage-banner-2"
          className="homepage-vector-bottom img-fluid w-100"
        />
        <div className="bottom-overlay">
          <Container>
            <Row>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="text-start">
                      <img
                        src={HomepageBottomMockup1}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-1"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-start">
                      <h4 className="daily-encouragement">
                        Daily Encouragement
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="text-center">
                      <img
                        src={HomepageBottomMockup2}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-2"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <h4>Personalized Prayer</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="text-end">
                      <img
                        src={HomepageBottomMockup3}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-3"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <h4 className="gratitudes-game">Gratitudes Game</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* ---------------------------------------------------Bottom mockups section mobile---------------------------------------------------*/}

      <div className="bottom-mobile-mockup-section-sm d-sm-none d-block">
        <img
          src={HomepageVectorBottomMobile}
          alt="homepage-banner-2-mobile"
          className="homepage-vector-bottom img-fluid w-100"
        />
        <div className="bottom-overlay-sm">
          <Container>
            <Row>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={HomepageBottomMockup1}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-1"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <h4 className="py-sm-0 py-5">Daily Encouragement</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={HomepageBottomMockup2}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-2"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <h4 className="py-sm-0 py-5">Personalized Prayer</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={HomepageBottomMockup3}
                        alt="byf-app"
                        className="img-fluid bottom-mockup-3"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center justify-content-center">
                      <h4 className="py-sm-0 py-5 text-blue">
                        Gratitudes Game
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* ---------------------------------------------------Bottom mockups section end----------------------------------------------------------*/}
      <Container className="container-bottom pt-sm-0 pt-5 mt-sm-4 mt-0">
        <Row>
          <Col sm={8}>
            <h5>
              Ease your stress and anxiety associated with medical procedures,
              treatment, medications and side effects. Encourage yourself and
              others and give life to your soul, body and mind.
            </h5>
            <div className="py-5 my-0 my-sm-5">
              <div className="bottom-button">
                <a href="https://app.buildyourfaithapp.com" className="btn btn-primary btn-bottom">
                  Try for Free
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default HomePage;
