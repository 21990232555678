import { createBrowserRouter } from "react-router-dom";
import NotFound from "./components/pages/errorpages/NotFound";
import ErrorComponent from "./components/common/ErrorComponent";
import Homepage from "./components/pages/homepage/HomePage"

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <Homepage />,
    errorElement: <ErrorComponent />,
    id: "root",
  },
]);
